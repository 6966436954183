.bft-light {
    height: 100%
}
.css-rcahu1-MyTree {
    height: 100%
}

.tab:hover {
    color: #2A769D;
    text-decoration: underline;
}

/* border color */
div.bft-light .bft-input>input{
    border-color: #5BA2BC ;
}
/* the hovered search resutt */
.bft-light .bft-search [data-search-item-id]:hover, .bft-light .bft-search [data-selected=yes] {
  background-color: #5BA2BC !important;
}
 /* input field on focus */
 div.bft-light .bft-input>input:focus {
    border-color: #2A769D;
}

/* the search label on focus */
.bft-light .bft-input>label.focused, .bft-dark .bft-input>label.focused {
  color: #2A769D !important;
}
.bft-edit-form-header {
    background-color: #C6E0F2;
}

.bft-img-button {
    background-color: #2A769D !important;
    
}

.bft-img-button:hover {
    background-color: #48A6D7 !important;
}

[data-ctrl-menu] {
    width: 30px !important;
    height: 30px !important;
}

.member-details {
    cursor: pointer
}

